











































































































































































































































































  .q-icon
    padding 5px
    &.rounded
      border-radius 20px !important

.performance-title
  color white
.performance-content
  margin-top 70px
  padding-top 10px
  background white
.performance-money
  font-size 28px
.performance-chart
  font-size 30px
  padding 0
  margin-right 15px
  margin-top 7px

.stat-icon-wrapper
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction column
.stat-icon
  font-size 60px
  color white
  width 100%
.stat-text
  color white
  width 100%
  text-align: center
.stat-number
  text-align right
  line-height 2
  color white
  font-size 45px
.manual-booking-card
  padding 12px
  color white
.new-booking-text
  display: flex;
  align-items: center;

.manual-booking-card-icon
  margin-top -10px
  font-size 48px
  color white

.manual-booking-card-text
  margin-top 26px

@media (min-width: 768px) {
  .performance-chart {
    font-size 3vw
  }
}

@media (min-width: 1199px) {
  .pot {
    width 33%
  }
}
.card-right
  display: flex;
  justify-content: right;
  flex-direction: column;
.number, .type
  flex-basis 100%
  width 100%
  margin-right 15px
.number
  font-size 36px
.type
  font-size 16px
  text-transform capitalize
.pot-card
  color white
  min-height 88px
.pot-card > *
  display: flex;
  align-items: center;
  justify-content: flex-start;









































































































































.card-right
  display: flex;
  justify-content: right;
  flex-direction: column;
.number
  font-size 20px
.type
  font-size 16px
  text-transform capitalize
.pot-card {
  color white
  padding 0

  .pot-card-header {
    border-bottom: 1px solid #eee
    > * {
      padding 5px
    }

    > :first-child {
      margin-left: 5px
    }

    > :last-child {
      padding 5px 10px
    }
  }
}
.pot-card .flex-grow
  flex-grow 1
.pot-card-header
  color: white
.pot-card-actions {
  padding 10px
  color: $primary
}

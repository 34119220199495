.card-right {
  display: flex;
  justify-content: right;
  flex-direction: column;
}
.number {
  font-size: 20px;
}
.type {
  font-size: 16px;
  text-transform: capitalize;
}
.pot-card {
  color: #fff;
  padding: 0;
}
.pot-card .pot-card-header {
  border-bottom: 1px solid #eee;
}
.pot-card .pot-card-header > * {
  padding: 5px;
}
.pot-card .pot-card-header > :first-child {
  margin-left: 5px;
}
.pot-card .pot-card-header > :last-child {
  padding: 5px 10px;
}
.pot-card .flex-grow {
  flex-grow: 1;
}
.pot-card-header {
  color: #fff;
}
.pot-card-actions {
  padding: 10px;
  color: #1976d2;
}
/*# sourceMappingURL=src/pages/dashboard/backoffice/pot-card.css.map */